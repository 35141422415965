import React, {useState, useEffect, useContext} from 'react';
import {navigate} from 'gatsby';
import styled from 'styled-components';
import * as AppContext from '../Contexts/AppContext';
import {s, d} from '../Utils/Lang';
import {ModalContent} from '../Components/ProductReserveModal';
import {aggregateProductVariants, isStockAvailable} from '../Utils/ProductUtil';
import {useDimension} from '../Hooks/AppHooks';
const queryString = require('query-string');

/* this page is for app only */
function AppProductReservePage(props) {
  const {location} = props;
  const [product, setProduct] = useState(null);
  const {dimension} = useDimension();
  const app = useContext(AppContext.Context);
  const [activeColor, setActiveColor] = useState(null);
  const [activeSize, setActiveSize] = useState(null);
  let parsed = queryString.parse(location.search);
  const id = parsed.id;

  const parsedLocationData = React.useMemo(() => {
    let _result = null;
    try {
      _result = JSON.parse(parsed.location);
    } catch (err) {
      //
    }
    return _result;
  }, [parsed.location]);

  useEffect(() => {
    async function fetchData() {
      AppContext.Actions.setLoading(true);
      const product = await AppContext.Actions.fetchProductById(id);

      const groupVariants = aggregateProductVariants(product.variants);
      let _uiProductData = {
        ...product,
        groupVariants: {
          colors: groupVariants.colors,
          size_options: groupVariants.sizes,
          stocks: groupVariants.stocks,
        },
      };

      const defaultAvailableVariant = product.variants.find(v => v.stock > 0);
      if (defaultAvailableVariant) {
        setActiveColor(defaultAvailableVariant.color);
      }

      setProduct(_uiProductData);
      AppContext.Actions.setLoading(false);
    }

    if (!id) {
      return;
    }

    fetchData();
  }, [location.search, id]);

  if (!product) {
    return null;
  }

  return (
    <Wrapper>
      <ModalContent
        activeColor={activeColor}
        setActiveColor={setActiveColor}
        activeSize={activeSize}
        setActiveSize={setActiveSize}
        product={product}
        location={parsedLocationData}
        onReserveBtnClick={(store, params) =>
          navigate(
            `/app-product-reserve?id=${id}&close=1&store=${
              store.id
            }&params=${JSON.stringify(params)}`,
          )
        }
        close={() => navigate(`/app-product-reserve?id=${id}&close=1`)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AppProductReservePage;
